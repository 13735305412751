import { combineReducers } from 'redux';

// reducer import
import accountReducer from './accountReducer';
import customizationReducer from './customizationReducer';
import formDetailsReducer from './formDetailsReducer';
import formReducer from './formsReducer';
import itemReducer from './itemReducer';
import { notificationReducer } from './notificationReducer';
import { permissionReducer } from './permissionReducer';
import projectsReducer from './projectReducer';
import snackbarReducer from './snackbarReducer';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    customization: customizationReducer,
    snackbar: snackbarReducer,
    account: accountReducer,
    projects: projectsReducer,
    form: formReducer,
    item: itemReducer,
    formDetails: formDetailsReducer,
    notification: notificationReducer,
    permission: permissionReducer
});

export type TRootState = ReturnType<typeof reducer>;

export default reducer;
