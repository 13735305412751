import CheckIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Button, Grid, Typography } from '@mui/material';
import axios from 'axios';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Loader from 'ui-component/Loader';
import { approveSignupURL } from 'utils/serverUrls';
import AuthCardWrapper from 'views/authentication/AuthCardWrapper';
import AuthWrapper from 'views/authentication/AuthWrapper';



const LOADING = 1;
const SUCCESS = 2;
const FAILED = 3;


const SuccessResponse = () => (<>
    <Grid item xs={12} sm={12} textAlign="center">

        <CheckIcon sx={{ fontSize: '900%', color: 'skyblue' }} />

        <Typography variant="h1" component="div" sx={{ fontWeight: '100' }}>Congratulations</Typography>
        <div style={{ padding: '5%' }}>
            <hr />
        </div>
        <Typography variant="subtitle1">
            Your account has been activated successfully.
        </Typography>
        <Typography >
            You can now sign-in to your account.
        </Typography>
    </Grid>
    <Grid container>
        <Grid item xs={12} sx={{ margin: 3, textAlign: 'center' }}>
            <Button component={Link} to="/login" color="primary" variant="contained">
                Sign in
            </Button>
        </Grid>
    </Grid>
</>)

const ErrorResponse = () => (<>
    <Grid item xs={12} sm={12} textAlign="center">

        <ErrorOutlineIcon sx={{ fontSize: '900%', color: 'red' }} />

        <Typography variant="h1" component="div" sx={{ fontWeight: '100' }}>Failed</Typography>
        <div style={{ padding: '5%' }}>
            <hr />
        </div>
        <Typography variant="subtitle1">
            Sorry !. can not verify your account.
            </Typography>
    </Grid>
</>)


export default function VerifyEmail() {

    const [stage, setStage] = useState<number>(LOADING);

    useEffect(() => {
        (async () => {
            const params = queryString.parse(window.location.search);
            const data = {
                data: {
                    type: "approve",
                    id: params.id,
                }
            };
            try {
                const response = await axios.post(`${process.env.REACT_APP_SERVICE_URL}/${approveSignupURL}`, data);
                console.log(response);
                localStorage.clear();
                sessionStorage.clear();
                setStage(SUCCESS);
            } catch (ex) {
                console.log(ex);
                setStage(FAILED);
            }
        })();

    }, []);
    return (
        <AuthWrapper>
            <Grid container justifyContent="center">
                <AuthCardWrapper >
                    {stage === LOADING ? <><Loader /> Verifying.. </> : null}
                    {stage === SUCCESS ? <SuccessResponse /> : null}
                    {stage === FAILED ? <ErrorResponse /> : null}
                </AuthCardWrapper>
            </Grid>

            {/* <Grid container>
                <Grid item xs={12} sx={{ margin: 3, textAlign: 'center' }}>
                    <Button component={Link} to="/login" color="primary">
                        {langString('login')}
                    </Button>
                </Grid>
            </Grid> */}
        </AuthWrapper>
    );
}
