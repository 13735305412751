import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayout = () => (
    <>
        <Box sx={{ background: '#0C67AA' }}>
            <Outlet />
        </Box>
    </>
);

export default MinimalLayout;
