// material-ui
import {Box, Grid} from '@mui/material';
import {useTheme} from '@mui/material/styles';

// project import
import MainCard, {MainCardProps} from 'ui-component/cards/MainCard';

// ==============================|| AUTHENTICATION CARD WRAPPER ||============================== //

const AuthCardWrapper = ({ children, ...other }: MainCardProps) => {
    const theme = useTheme();
    return (
        <MainCard
            sx={{
                maxWidth: { xs: 800, lg: 1000 },
                margin: { xs: 2.5, md: 3 },
                '& > *': {
                    flexGrow: 1,
                    flexBasis: '50%'
                }
            }}
            content={false}
            {...other}
        >
            <Grid container justifyContent={'center'}>
                <Grid item xs={8}>
                    <Box sx={{ p: { xs: 2, sm: 3, xl: 5 }, height: '100%' }}>{children}</Box>
                </Grid>
            </Grid>
        </MainCard>
    );
};

export default AuthCardWrapper;
