import defaultImage from 'assets/images/placeholder/default.png';
import { BASEURL } from './Constants';

export const fullFilePath = (picPath: string) => {
    if (!picPath) {
        return defaultImage;
    }
    if (picPath.includes('http')) {
        return picPath;
    }
    return `${BASEURL}${picPath
        ?.split('/')
        .filter((v) => v !== '')
        .join('/')}`;
};

export const checkNullInfo = (info: string | null | undefined) => {
    if (info) {
        return info;
    }
    return '---';
};

export const getFieldLabel = () => { };

export const getToken = (storageName: string) => {
    let token = null;
    const rememberMe = JSON.parse(localStorage.getItem('rememberMe') as any);

    if (rememberMe) {
        token = window.localStorage.getItem(storageName);
    } else {
        token = window.sessionStorage.getItem(storageName);
    }
    return token;
};

export const setToken = (storageName: string, value: string) => {
    let token = null;
    const rememberMe = JSON.parse(localStorage.getItem('rememberMe') as any);

    if (rememberMe) {
        token = window.localStorage.setItem(storageName, value);
    } else {
        token = window.sessionStorage.setItem(storageName, value);
    }
    return token;
};

export const errMsg = (ex: any) => {
    if (ex.response.data.errors) {
        return ex.response.data.errors.map((e: any) => e.detail).join(', ');
    }
    return ex.response.data;

};
