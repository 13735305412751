// third-party
// assets
import {
    IconBell,
    IconBoxMultiple,
    IconBuildingCommunity,
    IconBuildingStore,
    IconBulb,
    IconCirclePlus,
    IconClipboardList,
    IconDashboard,
    IconFileCode,
    IconFileText,
    IconGift,
    IconHelp,
    IconSchool,
    IconSettings,
    IconSitemap,
    IconTag,
    IconUpload,
    IconUser,
    IconUsers
} from '@tabler/icons';
import {FormattedMessage} from 'react-intl';

// constant
const icons = {
    IconDashboard,
    IconHelp,
    IconSitemap,
    IconUsers,
    IconUser,
    IconFileCode,
    IconBuildingCommunity,
    IconClipboardList,
    IconUpload,
    IconCirclePlus,
    IconBulb,
    IconBoxMultiple,
    IconFileText,
    IconTag,
    IconSettings,
    IconGift,
    IconBuildingStore,
    IconSchool
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const other = {
    id: 'sample-docs-roadmap',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: <FormattedMessage id="dashboard" />,
            type: 'item',
            url: '/',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'createItem',
            title: <FormattedMessage id="createItem" />,
            type: 'item',
            url: '/item/create',
            icon: icons.IconCirclePlus,
            breadcrumbs: false
        },
        {
            id: 'itemList',
            title: <FormattedMessage id="itemList" />,
            type: 'item',
            url: '/items',
            icon: icons.IconBulb,
            breadcrumbs: false
        },
        {
            id: 'notifications',
            title: <FormattedMessage id="notification" />,
            type: 'item',
            url: '/notifications',
            icon: IconBell,
            breadcrumbs: false
        },
        {
            id: 'popup',
            title: <FormattedMessage id="popup" />,
            type: 'item',
            url: '/popup',
            icon: icons.IconBoxMultiple,
            breadcrumbs: false
        },
        {
            id: 'my_files',
            title: <FormattedMessage id="myFiles" />,
            type: 'item',
            url: '/my-files',
            icon: icons.IconFileText,
            breadcrumbs: false
        },
        {
            id: 'user_management',
            title: <FormattedMessage id="userManagement" />,
            type: 'item',
            url: '/users',
            icon: icons.IconUser,
            breadcrumbs: false
        },
        {
            id: 'pricing_plan',
            title: <FormattedMessage id="pricingPlan" />,
            type: 'item',
            url: '/pricing-plan',
            icon: icons.IconTag,
            breadcrumbs: false
        },
        {
            id: 'shop_item',
            title: <FormattedMessage id="shopItem" />,
            type: 'item',
            url: '/shop-item',
            icon: icons.IconBuildingStore,
            breadcrumbs: false
        },
        {
            id: 'achievement',
            title: <FormattedMessage id="globalAchievement" />,
            type: 'item',
            url: '/achievement/',
            icon: icons.IconGift,
            breadcrumbs: true
        },
        {
            id: 'settings',
            title: <FormattedMessage id="settings" />,
            type: 'item',
            url: '/settings',
            icon: icons.IconSettings,
            breadcrumbs: false
        },
        {
            id: 'class-request',
            title: <FormattedMessage id="class-request" />,
            type: 'item',
            url: '/class-request',
            icon: icons.IconSchool,
            breadcrumbs: false
        }
        // {
        //     id: 'experimentalStage',
        //     title: <FormattedMessage id="experimentalStage" />,
        //     type: 'item',
        //     url: '/experiment/stage',
        //     icon: icons.IconSettings,
        //     breadcrumbs: false
        // }

        // {
        //     id: 'allprojects',
        //     title: <FormattedMessage id="allprojects" />,
        //     type: 'item',
        //     url: '/',
        //     icon: icons.IconDashboard,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'team_management',
        //     title: <FormattedMessage id="teamManagement" />,
        //     type: 'collapse',
        //     url: '/',
        //     icon: icons.IconUsers,
        //     breadcrumbs: false,
        //     children: [
        //         {
        //             id: 'role_management',
        //             title: <FormattedMessage id="roleManagement" />,
        //             type: 'item',
        //             url: '/roles',
        //             icon: icons.IconClipboardList,
        //             breadcrumbs: false
        //         },
        //         {
        //             id: 'user_management',
        //             title: <FormattedMessage id="userManagement" />,
        //             type: 'item',
        //             url: '/users',
        //             icon: icons.IconUser,
        //             breadcrumbs: false
        //         },
        //         {
        //             id: 'org_management',
        //             title: <FormattedMessage id="orgManagement" />,
        //             type: 'item',
        //             url: '/organizations',
        //             icon: icons.IconBuildingCommunity,
        //             breadcrumbs: false
        //         }
        //     ]
        // },
        // {
        //     id: 'forms',
        //     title: <FormattedMessage id="formdesignandupload" />,
        //     type: 'item',
        //     url: '/forms',
        //     icon: icons.IconFileCode,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'notifications',
        //     title: <FormattedMessage id="Notifications" />,
        //     type: 'item',
        //     url: '/notifications',
        //     icon: IconBell,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'permission',
        //     title: <FormattedMessage id="Permission" />,
        //     type: 'item',
        //     url: '/permission',
        //     icon: LockIcon,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'feedback',
        //     title: <FormattedMessage id="feedback" />,
        //     type: 'item',
        //     url: '/feedback',
        //     icon: icons.IconHelp,
        //     breadcrumbs: false
        // },
    ]
};
export const superUser = {
    id: 'sample-docs-roadmap',
    type: 'group',
    children: [
        {
            id: 'user-create',
            title: <FormattedMessage id="User Create" />,
            type: 'item',
            url: '/super-user/users-create',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'acl',
            title: <FormattedMessage id="ACL" />,
            type: 'item',
            url: '/super-user/acl',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'forum-acl',
            title: <FormattedMessage id="Forum ACL" />,
            type: 'item',
            url: '/super-user/forums',
            icon: icons.IconDashboard,
            breadcrumbs: false
        }
    ]
};

export default other;
