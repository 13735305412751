// project imports
import MainLayout from 'layout/MainLayout';
import { lazy } from 'react';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
// import Feedback from 'views/Feedback';
// import Forms from 'views/Forms';
// import FormUpload from 'views/Forms/Upload';
// import Profile from 'views/Profile';
// import ProjectDetails from 'views/Projects/Details';
// import FormProfile from 'views/Projects/Details/Forms/FormProfile';
// import ProjectTeamMemberAdd from 'views/Projects/Details/Teams/Add';
// sample page routing
// const Projects = Loadable(lazy(() => import('views/Projects')));
const Dashboard = Loadable(lazy(() => import('views/Dashboard')));
// const QuizCreator = Loadable(lazy(() => import('views/Item/create')));
// const ItemList = Loadable(lazy(() => import('views/Item/list')));

// const RoleManagement = Loadable(lazy(() => import('views/TeamManagement/RoleManagement')));
const UserManagement = Loadable(lazy(() => import('views/TeamManagement/UserManagement')));
// const FormDetails = Loadable(lazy(() => import('views/Projects/Details/Forms/FormProfile/Details')));
// const UserFormData = Loadable(lazy(() => import('views/Projects/Details/Forms/FormProfile/UserFormData')));
// const FormSettings = Loadable(lazy(() => import('views/Projects/Details/Forms/FormProfile/Settings')));
// const NewSubmission = Loadable(lazy(() => import('views/Projects/Details/Forms/FormProfile/NewSubmission')));
// const Organization = Loadable(lazy(() => import('views/TeamManagement/OrgManagement')));
const Notifications = Loadable(lazy(() => import('views/Notifications')));
const Stage = Loadable(lazy(() => import('views/Item/stage')));
const Unit = Loadable(lazy(() => import('views/Item/unit')));
const CreateAchievement = Loadable(lazy(() => import('views/Achievement/AchievementForm')));
const Achievement = Loadable(lazy(() => import('views/Achievement/')));
const MyFiles = Loadable(lazy(() => import('views/MyFiles/')));
const ShopItem = Loadable(lazy(() => import('views/Shop')));
const ClassRequest = Loadable(lazy(() => import('views/ClassRequest')));

// const Permission = Loadable(lazy(() => import('views/Permission')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <Dashboard />
        },
        {
            path: '/item/create',
            element: <Stage />
        },
        {
            path: '/items/',
            element: <Unit />
        },
        {
            path: '/my-files/',
            element: <MyFiles />
        },
        // {
        //     path: '/project/:id',
        //     element: <ProjectDetails />
        // },
        // {
        //     path: '/project/:id?tabIndex=:tabIndex',
        //     element: <ProjectDetails />
        // },
        // {
        //     path: '/project/:id/add-team-member/',
        //     element: <ProjectTeamMemberAdd />
        // },
        // {
        //     path: '/forms/',
        //     element: <Forms />
        // },
        // {
        //     path: '/feedback',
        //     element: <Feedback />
        // },
        // {
        //     path: '/profile',
        //     element: <Profile />
        // },
        // {
        //     path: '/roles',
        //     element: <RoleManagement />
        // },
        // {
        //     path: '/organizations',
        //     element: <Organization />
        // },
        {
            path: '/users',
            element: <UserManagement />
        },
        // {
        //     path: '/project/:projectId/form/:formId',
        //     element: <FormProfile />
        // },
        // {
        //     path: '/project/:projectId/form/:formId/details',
        //     element: <FormDetails />
        // },
        // {
        //     path: '/project/:projectId/form/:formId/data/:userId',
        //     element: <UserFormData />
        // },
        // {
        //     path: '/project/:projectId/form/:formId/settings',
        //     element: <FormSettings />
        // },
        // {
        //     path: '/project/:projectId/form/:formId/new-submission',
        //     element: <NewSubmission />
        // },
        // {
        //     path: '/form-upload',
        //     element: <FormUpload />
        // },
        {
            path: '/notifications',
            element: <Notifications />
        },
        {
            path: '/Achievement/',
            element: <Achievement />
        },
        {
            path: '/Achievement/create',
            element: <CreateAchievement />
        },
        {
            path: '/shop-item',
            element: <ShopItem />
        },
        {
            path: '/class-request',
            element: <ClassRequest />
        }
        // {
        //     path: '/experiment/stage',
        //     element: <ExperimentalStage />
        // }
        // {
        //     path: '/permission',
        //     element: <Permission />
        // }
    ]
};

export default MainRoutes;
